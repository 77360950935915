//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
    Typography,
    Grid,
    withStyles,
} from "@material-ui/core";

import { Theme } from "@material-ui/core/styles"
import { withRouter } from "react-router";
import { mainLogo, firstSideImg, sidePage2, sidePage1, sidePage3, resendImage, mobileArrow } from "./assets";
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { CONSTANTS } from "../../../components/src/theme";
import Hidden from "@material-ui/core/Hidden";
import { mobileLogo } from "../../LanguageSupport/src/assets";
import { goBack } from "../../../components/src/CommonFunction";

import OtpPasswordControllerWeb, { Props } from "./OtpPasswordController.web";
import OtpInput from "react-otp-input";
import { withSnackbar } from "../../../components/src/toastweb";
import { StyledButton } from "../../email-account-registration/src/utility.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

export class OtpPassword extends OtpPasswordControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderData = (comingFrom: any, classes: any) => {
    
        if (comingFrom === "Login" || comingFrom === "Signup") {
            const {t}=this.props;
            return (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h5" className={classes.titletext}>{t('OtpScreen.twofactor')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body2"
                            className={classes.resendDescriptionText}>
                            {t('OtpScreen.secondSubHeading')} <span>{this.state.email}</span></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant="body2"
                            className={classes.resendtextV1}>
                           {t('OtpScreen.authenticateText')}
                        </Typography>
                    </Grid>

                </>
            )
        }
    }
    renderOtpTypo = (comingFrom: any, classes: any) => {
        if (comingFrom !== "Login" && comingFrom !== "Signup") {
            const {t}=this.props;
            return (
                <Grid item xs={12}>
                    <Typography variant="h5" className={classes.titletext}>{t('OtpScreen.heading')}</Typography>
                    <Typography variant="body1" className={classes.verificationText}>{t('OtpScreen.subHeading')} {this.state.resendEmail}</Typography>
                </Grid>
            )
        }
    }
    renderOtpText = (comingFrom: any) => {
        const {t}=this.props;

        return (
            <>{t('OtpScreen.Otp')}</>)

    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        //@ts-ignore
        const { t,classes } = this.props

        return (

            <>
                <form onSubmit={this.handleSubmitotp}>
                    <Grid container direction="row" alignItems="center" className={classes.imageLayout}>
                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                            <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={classes.otpBgImage} />
                        </Hidden>
                        <Hidden only={'xs'}>
                            <Grid item xs={12} sm={1} lg={2} xl={3} className={classes.otpSecondLogo}>
                                <img style={{ width: "80px" }} src={firstSideImg} alt="firstSideImg" />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={5} md={5} lg={4} xl={4} style={{ padding: 30 }}>
                            <Grid container direction="row" spacing={2} >
                                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <Grid item xs={12} >
                                        <img className={classes.backIcon} src={mobileArrow}
                                            data-test-id='back_navigation'
                                            onClick={() => goBack(this.props.navigation)}
                                            width={12} height={24} alt="mobile arrow" />
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12}>
                                    <img src={mainLogo} className={classes.logoImg} />
                                </Grid>
                                {this.renderData(this.state.comingFrom, classes)}
                                {this.renderOtpTypo(this.state.comingFrom, classes)}
                                <Grid item xs={12} >
                                    <OtpInput
                                        value={this.state?.otpcode}
                                        onChange={this.handleChange}
                                        numInputs={6}
                                        isInputNum={true}
                                        shouldAutoFocus={true}
                                        inputStyle={this.state.isErrorotpData.otpcode ? classes.errorInputStyle : classes.inputStyle}
                                        focusStyle={classes.focusStyle}
                                        data-test-id="enterOtp"
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="error">{this.state.isErrorotpData.otpcode}</Typography>
                                    {this.state.otpErrorStatus && <Typography variant="body2" color="error">{t('OtpScreen.validOtp')}</Typography>}

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={6} container style={{ gap: "2rem" }} justifyContent={this.state.isResendDisabled ? "flex-end" : "space-between"}>

                                            {!this.state.isResendDisabled && <Typography data-test-id="resendOtpText" variant="body2" className={classes.resendtextV1}>{t('OtpScreen.resendText')}{this.state.timer} {t('OtpScreen.sec')} </Typography>}

                                        </Grid>
                                        <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "0.5rem" }}>
                                            <img width={"20px"} height={"20px"} src={resendImage} />
                                            <Typography data-test-id="resendOtp" variant="body2" className={this.state.isResendDisabled ? classes.resendtextDisabled : classes.resendtextEnabled} onClick={this.handleSendOTP}>{t('OtpScreen.resend')}
                                                
                                            </Typography>
                                        </Grid>
                                        <Hidden only={'xs'}>

                                            <Grid item xs={12} container justifyContent="flex-end">
                                                <StyledButton className={classes.verifyBtn} data-test-id="MObileotpBtn" type="submit" >
                                                    <Typography variant="body2" className={classes.btntext}>{t('OtpScreen.verify')}</Typography>
                                                </StyledButton>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </Grid>
                        <Grid item xs={12} sm={1} md={2} lg={3} />
                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                            <Grid item xs={12} className={classes.otpMobileButton}  >
                                <StyledButton className={classes.verifyBtn} data-test-id="otpBtn" type="submit" >
                                    <Typography variant="body2" className={classes.btntext}>{t('OtpScreen.verify')}</Typography>
                                </StyledButton>

                            </Grid>
                            <Grid item xs={12} className={classes.otpSecondLogo}>
                                <img className={classes.otpSecondImage} src={firstSideImg} alt="firstSideImg" />
                            </Grid>
                        </Hidden>
                        <Hidden only={'xs'}>
                            <Grid item xs={12} sm={5} md={4} lg={3} xl={2} className={classes.otpBgImage} />
                        </Hidden>

                    </Grid>
                </form>
                <LoadingBackdrop
                    loading={this.state.otpLoader}
                />
            </>

        );
        // Customizable Area End
    }
}

// Customizable Area Start
//@ts-ignore
const OtpPasswordRouter = withRouter(OtpPassword)
const OtpPasswordToast = withSnackbar(OtpPasswordRouter)
export const OtpStyles = (theme: Theme) => ({

    titletext: {
        fontWeight: CONSTANTS.fontWeight.fontWeight600,
        paddingTop: "1rem",
        [theme.breakpoints.down('xs')]: {
            paddingTop: 0,
            fontSize: 30
        },
    },
    logoImg: {
        width: "150px",
        [theme.breakpoints.between("xs", "sm")]: {
            width: "100px",
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "120px",
        },
    },
    btntext: {
        fontWeight: CONSTANTS.fontWeight.fontWeight400,
        textTransform: "none"
    },
    resendtextV1: {
        marginTop: "2px",
        marginBottom: "2px",
        color: CONSTANTS.color.starDust,
    },
    resendtextDisabled: {
        borderBottom: "1px solid #1C18AF",
        marginTop: "2px",
        marginBottom: "2px",
        color: "#1C18AF",
        cursor: "pointer",
        fontWeight: 400

    },
    resendtextEnabled: {
        borderBottom: "1px solid #96948f",
        marginTop: "2px",
        marginBottom: "2px",
        color: "#96948f",
        fontWeight: 400

    },
    resendtext: {
        borderBottom: `1px solid ${CONSTANTS.color.darkBlue}`,
        marginTop: "2px",
        marginBottom: "2px",
        color: CONSTANTS.color.darkBlue,
        cursor: "pointer",
        // marginRight: "20px"
    },
    otpBgImage: {
        backgroundImage: `url(${sidePage1})`,
        height: "100vh",
        backgroundSize: "100% 100%",
        position: "relative",
        [theme.breakpoints.down("xs")]: {
            backgroundImage: `url(${mobileLogo})`,
            maxHeight: "86px"
        },
        [theme.breakpoints.up("sm")]: {
            backgroundImage: `url(${sidePage1})`,
        },
        [theme.breakpoints.between("sm", 'md')]: {
            backgroundImage: `url(${sidePage2})`,
        },
        [theme.breakpoints.between('md', "lg")]: {
            backgroundImage: `url(${sidePage3})`,
        },
    },
    verificationText: {
        color: "#000000",
        fontWeight: 400
    },
    inputStyle: {
        width: "50px !important",
        height: "50px !important",
        textAlign: "center",
        marginRight: "20px",
        border: 'none',
        background: 'transparent',
        boxShadow: 'none',
        color: theme.palette.common.black,
        outline: 'none',
        borderBottom: `solid 2px ${CONSTANTS.color.ashGrey}`,
        caretColor: theme.palette.common.black,
        [theme.breakpoints.down(900)]: {
            width: "40px !important",
            height: "40px !important",
            marginRight: "15px",
        },
        [theme.breakpoints.down(650)]: {
            width: "40px !important",
            height: 25,
            marginRight: 15,
        },
        [theme.breakpoints.up(1400)]: {
            width: "60px !important",
            height: 60,
            marginRight: 15,
        },

    },
    focusStyle: {
        borderBottom: `solid 2px ${theme.palette.text.primary} `,

        outline: "none"
    },
    errorInputStyle: {
        width: "50px !important",
        height: "50px !important",
        textAlign: "center",
        marginRight: "20px",
        border: 'none',
        background: 'transparent',
        boxShadow: 'none',
        color: theme.palette.error.main,
        outline: 'none',
        borderBottom: `solid 2px ${theme.palette.error.main}`,
        caretColor: theme.palette.common.black,
        [theme.breakpoints.down(900)]: {
            width: "40px !important",
            height: "40px !important",
            marginRight: "15px",
        },
        [theme.breakpoints.down(650)]: {
            width: "40px !important",
            height: 25,
            marginRight: 15,
        },
        [theme.breakpoints.up(1400)]: {
            width: "60px !important",
            height: 60,
            marginRight: 15,
        },
    },
    otpSecondLogo: {
        display: 'flex',
        alignItems: 'end',
        height: '100%',
        [theme.breakpoints.down("xs")]: {
            position: 'absolute',
            bottom: 0,
            zIndex: -99
        },
    },
    verifyBtn: {
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        }
    },
    imageLayout: {
        [theme.breakpoints.up("sm")]: {
            height: '100%'
        },
    },
    otpMobileButton: {
        [theme.breakpoints.down("xs")]: {
            position: 'absolute',
            bottom: '10%',
            width: '100%',
            padding: '0 20px'
        },
    },
    otpSecondImage: {
        width: "80px",
        [theme.breakpoints.down("xs")]: {
            width: "47px",
            height: '47px'
        },
    },
    resendDescriptionText: {
        marginTop: "2px",
        marginBottom: "2px",
        color: CONSTANTS.color.starDust,
        [theme.breakpoints.down('xs')]: {
            fontSize: 12
        }
    },


})
const OtpComponent = withStyles(OtpStyles)(OtpPasswordToast)
export default withTranslation()(OtpComponent);
// Customizable Area End