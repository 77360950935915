import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
import React from "react";
import DatePicker from "react-datepicker";
import { performInvoiceAuthorizationCheck, performInvoiceDeleteAuthorizationCheck } from "../../CustomisableUserProfiles/src/utility.web";
import { checkTokenExpired } from "../../../components/src/CommonFunction";
import {WithStyles} from "@material-ui/core"
import { setStorageData } from "framework/src/Utilities";
interface RequestStatusData {
  id:number,
  title:string,
  statusName:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  openToastHandler: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  statusModal: boolean;
  toggleModal: boolean;
  information: boolean;
  purchases: boolean;
  status: string;
  selectedDate:{
    startDate: Date,
    endDate: null|Date
  },
  dateStatus:string,
  suppliersData: any;
  getRequestsLoading: boolean;
  sortDirection: any;
  sortColumn: any;
  requestToggleData: any;
  searchRequest: any;
  allRequests: any;
  statusName: string;
  deleteRowItem: boolean
  checkStatus: string
  hardDeleteStatus: boolean
  allRequestsList: any,
  deleteIconStatus: boolean
  selectRowId: string,
  renderToasterStatus: boolean
  hoverRow: any;
  renderToasterText: string
  selectedLanguage:string
  checkedItems: string[]
  dateModal: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  datePickerRef: React.RefObject<DatePicker>
  // Customizable Area End
}

export default class RequestsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchRequestApiCallId: string = "";
  getStatusRequestsApiCallId: string = "";
  deleteApiCallId: string = ""
  datePickerRef: React.RefObject<DatePicker>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    const RequestToggleData = [
      { id: 1, title: `${this.props.t('request.title')}`, hidden: true },
      { id: 2, title: `${this.props.t('invoice.menuLabel.cusName')}`, hidden: true },
      { id: 3, title: `${this.props.t('request.source')}`, hidden: true },
      { id: 4, title: `${this.props.t('request.subTitles.dateText')}`, hidden: true },
      { id: 5, title: `${this.props.t('request.subTitles.timeText')}`, hidden: false },
    ]
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      statusModal: false,
      toggleModal: false,
      information: false,
      purchases: false,
      status: "all",
      selectedDate: {
        startDate: new Date(),
        endDate: null
      },
      dateStatus:`${this.props.t('dashboard.today')}`,
      suppliersData: [],
      getRequestsLoading: false,
      sortDirection: "asc",
      sortColumn: "",
      requestToggleData: RequestToggleData,
      searchRequest: "",
      allRequests: [],
      statusName: "All",
      checkedItems:["all","pending","quoted","converted_to_job","overdue","cancelled"],
      deleteRowItem: false,
      checkStatus: "",
      hardDeleteStatus: false,
      allRequestsList: [],
      deleteIconStatus: false,
      selectRowId: "",
      renderToasterStatus: false,
      renderToasterText: "",
      hoverRow: '',
      selectedLanguage:'',
      dateModal: false,
    };
    this.datePickerRef = React.createRef<DatePicker>()
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    const selectedLanguage = await getStorageData("lang");
    this.setState({ selectedLanguage: selectedLanguage })
    if (!localStorage.getItem("authToken")) {
      this.props.navigation.history.push("/Login");
    }
    this.sortRequestManage()
    this.setState({ getRequestsLoading: true });
    const RequestDeleteStatus = await getStorageData(configJSON.requestStatus.deleted)
    await removeStorageData(configJSON.requestStatus.deleted)
    if (RequestDeleteStatus) {
      this.setState({ renderToasterStatus: true, renderToasterText: `${this.props.t('request.toasterStatusText.delete')}` },()=>{
        this.props.openToastHandler(this.state.renderToasterText,'success')
      });
    }
    const statuse = localStorage.getItem("status")
    if(statuse){this.setState({dateStatus:statuse})} 
      this.sortRequestManage()
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getStatusRequestsApiCallId) {
      this.handleStatusRequestsApiResponce(responseJson);

    }
    if (apiRequestCallId === this.deleteApiCallId) {
      this.handleDeleteApiResponce(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start 
  handleStatusRequestsApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      if (responseJson.message === "you are not authorized") {
        this.setState({
          getRequestsLoading: false,
        })
        return this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
      }
      this.setState({
        getRequestsLoading: false,
      })
      this.setState({ allRequestsList: responseJson },()=>{
        this.handleStatusRequestModalClose()
      })
    } else if (responseJson.errors[0].message === "Not found any request.") {
      this.setState({ allRequestsList: [] });
    }else{
      checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)

    }
  }
  handleDeleteApiResponce = (responceJson: any) => {
    if (responceJson && responceJson.message) {
      this.setState({
        renderToasterStatus: true,
        renderToasterText: `${this.props.t('request.toasterStatusText.hardDeleteText')}`,
        hardDeleteStatus: false
      })
      this.props.openToastHandler(`${this.props.t('request.toasterStatusText.hardDeleteText')}`, "success")
      this.sortRequestManage()
    }
  }

  deleteRequest = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const apiEndPoint = configJSON.deleteRequest
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.deleteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${apiEndPoint}/${this.state.selectRowId}?lang=${this.state.selectedLanguage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Api calls Ended
  handleChangeSearchRequest = (event: any) => {
    this.setState({ searchRequest: event.target.value }, () => {
      this.sortRequestManage();
    });
  }

  handleStatusRequestModalOpen = () => {
    this.setState({
      statusModal: !this.state.statusModal,
      searchRequest: ""
    });
    this.setState({ toggleModal: false });
  }

  handleStatusRequestModalClose = () => {
    this.setState({statusModal: false});
  }

  handleToggleRequestModalOpen = () => {
    this.setState({ toggleModal: !this.state.toggleModal });
    this.setState({ statusModal: false });
  }

  handleToggleRequestModalClose = () => {
    this.setState({ toggleModal: false });
  }

  handleSelectStatus = (item: RequestStatusData, quoteStatusData: RequestStatusData[], event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.checked) {
        if (item.statusName === "all") {
            this.setState({
                checkedItems: quoteStatusData.map((subItem) => subItem.statusName),
                status: "all"
            }, () => {this.sortRequestManage();
            })
        } else {
            this.setState({
                checkedItems: [...this.state.checkedItems,
                item.statusName
                ],
                status: [...this.state.checkedItems,
                item.statusName
                ].join(",")
            }, () => {this.sortRequestManage();
            })
        }
    } else {
        if (item.statusName === "all") {
            this.setState({
                checkedItems: [],
                status: ""
            }, () => {this.sortRequestManage();
            })
        } else {
            this.setState({
                checkedItems: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all"),
                status: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all").join(",")
            }, () => {this.sortRequestManage();
            })
        }
    }
}
  handleDateModalOpen = () => {
    this.setState({ dateModal: !this.state.dateModal });
  }
  handleStatusModalClose = () => {
    const { t } = this.props;
    if (this.state.dateStatus !== t('dashboard.custom')) {
      this.setState({ dateModal: false });
    }
  }
  handleDateChange = (dates: [Date, null]) => {
    const [startDate, endDate] = dates;
    this.setState({
      selectedDate: { ...this.state.selectedDate, startDate: startDate, endDate: endDate },
    }, () => {
      if (startDate && endDate) {
        this.sortRequestManage();
        this.setState({ getRequestsLoading: true });
        this.setState({ dateModal: false });
      }
    });
  };
  handleSelectDateStatus = (item: { title: string; }) => {
    const { t } = this.props;
    const { startDate, endDate } = this.state.selectedDate;
    setStorageData("status",item.title)
    this.setState({ dateStatus: item.title }, () => {
      if (item.title === t('dashboard.custom')) {
        if (startDate && endDate) {
          this.sortRequestManage();
          this.setState({ getRequestsLoading: true });
        }
      } else {
        this.sortRequestManage();
        this.setState({ getRequestsLoading: true });
      }
    });
    if (item.title !== t('dashboard.custom')) {
      this.handleStatusModalClose()
    }
  }
  handleCreateRequest = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("request");
    this.handleCreateRequestCallback(isAuthorized)
  }

  handleCreateRequestCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.props.navigation.history.push("/Request/Create");
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  }


  handleSortRequest = (columnKey: any) => {
    let newSortDirection: "asc" | "desc" = "asc";
    if (columnKey === this.state.sortColumn) {
      newSortDirection = this.state.sortDirection === "asc" ? "desc" : "asc";
    }
    this.setState({
      sortDirection: newSortDirection,
      sortColumn: columnKey
    },()=>{
      this.sortRequestManage()
    });
  };

  handleToggleColumnRequest = (id: any) => {
    const newToggleData = this.state.requestToggleData.map((item: any) => {
      if (item.id === id) {
        return { ...item, hidden: !item.hidden };
      }
      return item
    })

    this.setState({
      requestToggleData: newToggleData,
    });
  }
  handleRenderToaster = () => {
    this.setState({ renderToasterStatus: false });
  }
  handleViewRequestData = (requestDetails: { id: string; attributes: { site_id: string; }; }) => {
      this.props.navigation.history.push("/Request/Edit", {
        requestId: requestDetails.id,
        siteId: requestDetails.attributes.site_id
      });
  }
 
  handleDeleteDailog = async (rowId: string) => {
    const isAuthorized = await performInvoiceDeleteAuthorizationCheck("request");
    this.handleDeleteRequestButtonCallback(isAuthorized, rowId);
  };

  handleDeleteRequestButtonCallback = (isAuthorized: boolean, rowId: string) => {
    if (!isAuthorized) {
      this.setState({
        hardDeleteStatus: true,
        selectRowId: rowId
      })
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  }
  handleDeleteCloseDailog = () => {
    this.setState({
      hardDeleteStatus: false,
    })
  }
  statusAr = (dateStatus: string) => {
    let statusAr;
    if(dateStatus === "اليوم") {
      statusAr = "today";
    } else if(dateStatus === "هذا الاسبوع") {
      statusAr = "this_week";
    } else if(dateStatus === "هذا الشهر") {
      statusAr = "this_month";
    } else if(dateStatus === "مخصص") {
      statusAr = "custom";
    }
    return statusAr;
  }
  sortRequestManage = async () => {
    const { startDate, endDate } = this.state.selectedDate;
    const token = localStorage.getItem(configJSON.storageToken)
    const lang = localStorage.getItem("lang");
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token
    };
    const apiEndPoint = configJSON.getAllRequest
    let requestsUrl
    if (this.state.searchRequest&&this.state.sortColumn) {
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchRequest}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${lang}&start_date=${startDate}&end_date=${endDate}&date_filter=${lang === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }else if(this.state.searchRequest){
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchRequest}&lang=${lang}&start_date=${startDate}&end_date=${endDate}&date_filter=${lang === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }else if (this.state.sortColumn) {
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${lang}&start_date=${startDate}&end_date=${endDate}&date_filter=${lang === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }else{
      requestsUrl = `${apiEndPoint}?status=${this.state.status}&lang=${lang}&start_date=${startDate}&end_date=${endDate}&date_filter=${lang === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }
    this.setState(
        {getRequestsLoading:true}
    )

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getStatusRequestsApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), requestsUrl);
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}
}


// Customizable Area End