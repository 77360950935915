import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { t } from "i18next";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { performInvoiceAuthorizationCheck, performInvoiceDeleteAuthorizationCheck } from "../../CustomisableUserProfiles/src/utility.web";
import { apiCall, checkTokenExpired, filterListData,debounce } from "../../../components/src/CommonFunction";
import React from "react";
import DatePicker from "react-datepicker";
interface InvoiceStatusData {
  id:number,
  title:string,
  statusName:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  openToastHandler: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  deleteToaster: boolean;
  selectedLanguage:string
  statusModal: boolean;
  toggleModal: boolean;
  information: boolean;
  purchases: boolean;
  status: string;
  suppliersData: any;
  getRequestsLoading: boolean;
  sortDirection: any;
  sortColumn: any;
  requestToggleData: any;
  searchRequest: any;
  allRequests: any;
  statusName: string;
  deleteRowItem: boolean
  deleteInvoiceToaster:boolean
  errorMessage:any;
  checkStatus: string
  tableLoading:boolean;
  hardDeleteStatus: boolean
  allRequestsList: any,
  deleteIconStatus: boolean
  hoverRow:any;
  selectRowId: string,
  deleteToasterStatus: boolean
  toasterMsgStatus:boolean
  checkedItems:string[]
  dateModal: boolean
  selectedStatusForAPI:string
  selectedDate:{
    startDate: Date,
    endDate: null|Date
  },
  dateStatus:string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  datePickerRef: React.RefObject<DatePicker>
  // Customizable Area End
}

export default class InvoicesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getStatusRequestsApiCallId: string = "";
  deleteApiCallId: string = ""
  datePickerRef: React.RefObject<DatePicker>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    const RequestToggleData = [
      { id: 1, title: `${t('invoice.menuLabel.invoiceTitle')}`, hidden: true },
      { id: 2, title: `${t('invoice.menuLabel.cusName')}`, hidden: true },
      { id: 3, title: `${t('invoice.menuLabel.issueDate')}`, hidden: true },
      { id: 4, title: `${t('invoice.menuLabel.paymentDue')}`, hidden: true },
      { id: 5, title: `${t('invoice.menuLabel.productName')}`, hidden: true },
      { id: 6, title: `${t('invoice.menuLabel.serviceName')}`, hidden: false },
      { id: 7, title: `${t('invoice.menuLabel.jobReference')}`, hidden: false },
      { id: 8, title: `${t('invoice.menuLabel.totalPrice')}`, hidden: false },
    ]
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      deleteToaster: true,
      selectedLanguage:"",
      selectedStatusForAPI:"today",
      statusModal: false,
      toggleModal: false,
      information: false,
      purchases: false,
      deleteInvoiceToaster:false,
      status: "all",
      suppliersData: [],
      getRequestsLoading: false,
      errorMessage:null,
      sortDirection: "asc",
      sortColumn: "",
      requestToggleData: RequestToggleData,
      searchRequest: "",
      allRequests: [],
      statusName: "All",
      deleteRowItem: false,
      tableLoading:false,
      hoverRow:'',
      checkStatus: "",
      hardDeleteStatus: false,
      allRequestsList: [],
      deleteIconStatus: false,
      selectRowId: "",
      deleteToasterStatus: false,
      toasterMsgStatus:true,
      checkedItems:["all","awaiting_payment","payment_overdue","paid","bad_debt","archived"],
      dateModal: false,
      selectedDate: {
        startDate: new Date(),
        endDate: null
      },
      dateStatus:`${t('dashboard.today')}`
    };
    this.datePickerRef = React.createRef<DatePicker>()
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    const selectedLanguage = await getStorageData("lang");
    this.setState({ selectedLanguage: selectedLanguage })
    localStorage.removeItem('createFromCustomer')
    if (!localStorage.getItem("authToken")) {
      this.props.history.push("Login");
    }
    
    this.getStatusSearchInvoices()
    this.setState({ getRequestsLoading: true });
    if(this.props.location?.state?.showArchiveMessage){
      this.props.openToastHandler(`${t('invoice.invoiceCancel')}`, "success")
      this.props.history.replace() 
    }
    const statue = await getStorageData("status")
    const statueValue = await getStorageData("statusValue")
    this.setState({ dateStatus: statue,selectedStatusForAPI:statueValue})
    this.getStatusSearchInvoices();
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getStatusRequestsApiCallId) {
      this.handleStatusRequestsApiResponce(responseJson);

    }
    if (apiRequestCallId === this.deleteApiCallId) {
      this.handleDeleteApiResponce(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start 

  handleStatusRequestsApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({ 
        allRequestsList: filterListData(responseJson),statusModal: false
       },
       () => this.setState({ tableLoading: false, errorMessage: null, }))
    } else if (responseJson.errors) {
      checkTokenExpired(responseJson, this.props.openToastHandler, this.props.history)
      this.setState({ allRequests: [], errorMessage: responseJson?.errors[0]?.message, tableLoading: false });
    }
  }
  handleDeleteApiResponce = (responceJson: any) => {
    if (responceJson && responceJson.message) {
      this.setState({
        deleteToasterStatus: true,
        deleteInvoiceToaster:true,
        hardDeleteStatus: false,tableLoading:false
      })
      this.props.openToastHandler(`${t('invoice.hardDelete')}`, "success")
      this.getStatusSearchInvoices()
    }
  }
  getStatusSearchInvoices = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const apiEndPoint = configJSON.getInvoices
    const languageSelected = localStorage.getItem("lang");
    const { startDate, endDate } = this.state.selectedDate;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    this.setState({tableLoading:true})
    let getAllStatusInvoiceUrl 
    if(this.state.searchRequest){
      getAllStatusInvoiceUrl=`${apiEndPoint}?status=${this.state.status}&query=${this.state.searchRequest}&lang=${languageSelected}&start_date=${startDate}&end_date=${endDate}&date_filter=${this.state.selectedStatusForAPI}`
    } else{
      getAllStatusInvoiceUrl=`${apiEndPoint}?status=${this.state.status}&lang=${languageSelected}&start_date=${startDate}&end_date=${endDate}&date_filter=${this.state.selectedStatusForAPI}`
    }
    this.setState({tableLoading:true})
    const getStatusInvoiceApiCall = apiCall({
      header: header,
      httpBody:'',
      url:getAllStatusInvoiceUrl,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.getStatusRequestsApiCallId = getStatusInvoiceApiCall.messageId;
    runEngine.sendMessage(getStatusInvoiceApiCall.id, getStatusInvoiceApiCall);
  }
  debounceSearchInvoices = debounce(this.getStatusSearchInvoices, 700);
  deleteRequest = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const apiEndPoint = configJSON.getInvoices
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    this.setState({tableLoading:true})
    const deleteInvoiceApiCall = apiCall({
      header: header,
      httpBody: null,
      url: `${apiEndPoint}/${this.state?.selectRowId}?lang=${this.state.selectedLanguage}`,
      httpMethod: "DELETE",
    });
    this.deleteApiCallId = deleteInvoiceApiCall.messageId;
    runEngine.sendMessage(deleteInvoiceApiCall.id, deleteInvoiceApiCall);
  }
  // Api calls Ended
   
  handleChangeSearchRequest = (event: any) => {
    this.setState({ searchRequest: event.target.value }, () => {
      this.debounceSearchInvoices();
    });
  }
  handleStatusRequestModalOpen = () => {
    this.setState({ statusModal: !this.state.statusModal });
    this.setState({ toggleModal: false });
  }
  handleCloseToasterMsg = () => {
    this.setState({ toasterMsgStatus: false })
  }
  handleStatusInvoiceModalClose=()=>{
    this.setState({
      statusModal: false,
    })
  }
  handleToggleRequestModalOpen = () => {
    this.setState({ toggleModal: !this.state.toggleModal });
    this.setState({ statusModal: false });
  }
  handleToggleRequestModalClose = () => {
    this.setState({ toggleModal: false });
  }
  handleSelectStatus = (item: InvoiceStatusData, InvoiceStatusData: InvoiceStatusData[], event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.checked) {
      if (item.statusName === "all") {
        this.setState({
          checkedItems: InvoiceStatusData.map((subItem) => subItem.statusName),
          status: "all"
        }, () => {
          this.getStatusSearchInvoices();
        })
      } else {
        this.setState({
          checkedItems: [...this.state.checkedItems,
          item.statusName
          ],
          status: [...this.state.checkedItems,
          item.statusName
          ].join(",")
        }, () => {
          this.getStatusSearchInvoices();
        })
      }
    } else {
      if (item.statusName === "all") {
        this.setState({
          checkedItems: [],
          status: ""
        }, () => {
          this.getStatusSearchInvoices();
        })
      } else {
        this.setState({
          checkedItems: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all"),
          status: this.state.checkedItems.filter((subItem: string) => subItem !== item.statusName && subItem !== "all").join(",")
        }, () => {
          this.getStatusSearchInvoices();
        })
      }
    }
  }
  handleDateModalOpen = () => {
    this.setState({ dateModal: !this.state.dateModal });
  }
  handleStatusModalClose = () => {
    const { t } = this.props;
    if (this.state.dateStatus !== t('dashboard.custom')) {
      this.setState({ dateModal: false });
    }
  }
  handleDateChange = (dates: [Date, null]) => {
    const [startDate, endDate] = dates;
    this.setState({
      selectedDate: { ...this.state.selectedDate, startDate: startDate, endDate: endDate },
    }, () => {
      if (startDate && endDate) {
        this.getStatusSearchInvoices();
        this.setState({ getRequestsLoading: true });
        this.setState({ dateModal: false });
      }
    });
  };
  handleSelectDateStatus = (item: { title: string;value:string }) => {
    const { t } = this.props;
    setStorageData("status",item.title)
    setStorageData("statusValue",item.value)
    const { startDate, endDate } = this.state.selectedDate;
    this.setState({ dateStatus: item.title,selectedStatusForAPI:item.value }, () => {
      if (item.title === t('dashboard.custom')) {
        if (startDate && endDate) {
          this.getStatusSearchInvoices();
          this.setState({ getRequestsLoading: true });
        }
      } else {
        this.getStatusSearchInvoices();
        this.setState({ getRequestsLoading: true });
      }
    });
    if (item.title !== t('dashboard.custom')) {
      this.handleStatusModalClose()
    }
  }
  handleCreateInvoice = async () => {
    const isAuthorized = await performInvoiceAuthorizationCheck("invoice"); //true = not authorized , false = authorized
    this.handleCreateInvoiceCallback(isAuthorized);
  };
  handleCreateInvoiceCallback = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.props.history.push("/Invoice");
    } else {
      this.props.openToastHandler("You are not authorized.", "error");
    }
  };
  compareValues = (value1: any, value2: any) => {
    if (value1 === undefined || value1 === null) {
      return -1;
    }
    if (value2 === undefined || value2 === null) {
      return 1;
    }
    if (typeof value1 === "string" && typeof value2 === "string") {
      return value1.localeCompare(value2, "en", { sensitivity: "base" });
    }
    return value1 - value2;
  };
  returnOriginalColName=(value:string)=>{
    if(value==='invoice_title'){
      return 'title'
    }
    if(value==='customer_name'){
      return 'customer_name'
    }
    if(value==='issue_date'){
      return 'issue_date'
    }
    if(value==='payment_due'){
      return 'payment_due'
    }
    if(value==='product_name'){
      return 'product_name'
    }
    if(value==='service_name'){
      return 'service_name'
    }
    if(value==='job_reference'){
      return 'job_title'
    }
    if(value==='product_quantity'){
      return 'quantity'
    }
    return 'title'
  }
  renderProduct=(newSortDirection:any,columnKey:any)=>{
    Object.keys(this.state.allRequestsList)?.forEach((key: any) => {
      return this.state.allRequestsList[key]?.data.sort((a: any, b: any) => {
        const value1 = a.product.attributes[this.returnOriginalColName(columnKey)]||"";
        const value2 = b.product.attributes[this.returnOriginalColName(columnKey)]||'';
        const result = this.compareValues(value1, value2);
        return newSortDirection === "asc" ? result : -result;
      })
  })
  }
  renderAllCustomerList = (newSortDirection: any, columnKey: any) => {
    Object.keys(this.state.allRequestsList)?.forEach((key: any) => {
        return this.state.allRequestsList[key]?.data.sort((a: any, b: any) => {
          const value1 = a.attributes[this.returnOriginalColName(columnKey)];
          const value2 = b.attributes[this.returnOriginalColName(columnKey)];
          const result = this.compareValues(value1, value2);
          return newSortDirection === "asc" ? result : -result;
        })
    })
  }
  handleSortRequest = (columnKey: any) => {
    let newSortDirection: "asc" | "desc" = "asc";
    if (columnKey === this.state.sortColumn) {
      newSortDirection = this.state.sortDirection === "asc" ? "desc" : "asc";
    }
    if(columnKey==='product_name' || columnKey==='service_name' || columnKey==='product_quantity'){
      this.renderProduct(newSortDirection,columnKey)
    }else{
      this.renderAllCustomerList(newSortDirection,columnKey)
    }
    this.setState({
      allRequestsList: this.state.allRequestsList,
      sortDirection: newSortDirection,
      sortColumn: columnKey
    });
  };
  handleToggleColumnRequest = (id: any) => {
    const newToggleData = this.state.requestToggleData.map((item: any) => {
      if (item.id === id) {
        return { ...item, hidden: !item.hidden };
      }
      return item
    })

    this.setState({
      requestToggleData: newToggleData,
    });
  }
  handleCloseToasterDelete = () => {
    this.setState({ deleteToaster: false ,
    deleteToasterStatus:false});
    localStorage.removeItem("deleteToaster");
  }
  handleViewRequestData = (requestDetails: any) => {
    if (this.state.statusName === "Cancelled") {
      this.handleSelectIcon(requestDetails)
    } else {
      this.props.history.push("/Invoice", {
        customerId: requestDetails.attributes.customer_id,
        invoiceId: requestDetails.id,
        selectJobId:JSON.stringify(parseInt(requestDetails?.attributes?.job_id?.match(/(\d+)/)[0], 10))||null,
        siteId: requestDetails.attributes.site_id
      }); 
    }

  }
  handleSelectIcon = (requestDetails: any) => {
    this.setState({
      selectRowId: requestDetails.id,
    })
  }
  handleDeleteDailog = async (invoiceID:any) => {
    const isAuthorized = await performInvoiceDeleteAuthorizationCheck("invoice"); //true = not authorized , false = authorized
    this.handleDeleteInvoiceButtonCallback(isAuthorized,invoiceID);
  };
  handleBackIcon = () => {
    this.props.navigation.history.goBack();
  }
  handleDeleteInvoiceButtonCallback = (isAuthorized: any,invoiceID:any) => {
    if (!isAuthorized) {
      this.setState({
        hardDeleteStatus: true, selectRowId: invoiceID.id,
      })
    } else {
      this.props.openToastHandler("You are not authorized.", "error");
    }
  }
  handleDeleteCloseDailog = () => {
    this.setState({
      hardDeleteStatus: false,
    })
  }
}
// Customizable Area End