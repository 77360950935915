import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { checkLoggedInUser, performSupplierAuthorizationCheck } from "./utility.web";
import { apiCall } from "../../../components/src/CommonFunction";
import {  ValueType  } from "react-select";
 interface TaxRateResponse {
  id: number;
  company_information_id: number;
  name: string;
  rate: number;
  description: string;
  created_at: string;
  updated_at: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  oldProps: any;
  openPdf: any;
  handleCloseDownloadPdf: any;
  purchasePdfData: any;
  purchasePdfLoading: any;
  openToastHandler: any;
  openPdftype?:string
  currency:string
  t: (key: string) => string;
  languageSelected: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  deleteDialogOpen: boolean;
  checkedId: any;
  discountDialog: boolean;
  taxDialog: boolean;
  depositDialog: boolean;
  methodDialog: boolean;
  formData: {
    purchaseDate: any;
    price: any;
    quantity: any;
    itemDescription: any;
  }
  isErrorFormData: any;
  isErrorDiscountFormData: any;
  isErrorDepositFormData: any;
  isErrorTaxFormData: any;
  currency: string;
  selectCurrency: string | undefined;
  discount: any;
  taxFormData: {
    taxName: any;
    taxRate: number;
  };
  depositFormData: {
    method: any;
    amount: any;
    transactionDate: any;
    details: any;
  };
  purchasesData: any;
  noPurchaseText: any;
  createPurchasesLoading: boolean;
  getSinglePurchasesLoading: boolean;
  getPurchasesLoading: boolean;
  deletePurchaseLoading: boolean;
  editPurchaseLoading: boolean;
  data: any;
  isEdit: boolean;
  subTotal: any;
  total: any;
  singlePurchaseData: any;
  openPdf: boolean;
  purchasePdfData: any;
  purchasePdfLoading: any;
  languageSelected: string;
  collectedPaymentForm: { amount?:string |number; payment_method?:string; transaction_date?:Date; details?:string; pay_balance?: boolean; collected?: boolean },
  priceSectionData:{ sub_total:string; total:string; tax:number | null; tax_name:string; discount:number | null; },
  anchorId:string,
  anchorEl: Element | null;
  taxRates:TaxRateResponse[]
  taxAnchorId:string,
  taxAnchorEl: Element | null;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PurchasesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  wrapperRef: any;
  createPurchasesApiCallId: string = "";
  getPurchasesApiCallId: string = "";
  getSinglePurchaseApiCallId: string = "";
  deletePurchasesApiCallId: string = "";
  editPurchasesApiCallId: string = "";
  getPurchasesPdfApiCallId: string = "";
  getTaxListsApiCallId: string = "";
  createTaxRateApiCallId: string = '';
  getCurrencyApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handleDiscountDialogOpen = this.handleDiscountDialogOpen.bind(this)
    this.handleChangePaymentMethodRef=this.handleChangePaymentMethodRef.bind(this)
    this.handleDiscountDialogOpen = this.handleDiscountDialogOpen.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      taxRates:[],
      taxAnchorEl:null,
      taxAnchorId:"",
      anchorEl:null,
      anchorId:"",
      deleteDialogOpen: false,
      checkedId: null,
      discountDialog: false,
      taxDialog: false,
      depositDialog: false,
      methodDialog: false,
      formData: { purchaseDate: new Date(), price: null, quantity: null, itemDescription: "", },
      collectedPaymentForm: { amount:'', payment_method:'', transaction_date:new Date(), details:'', pay_balance: false, collected: false },
      isErrorFormData: {},
      isErrorDiscountFormData: {},
      isErrorDepositFormData: {},
      isErrorTaxFormData: {},
      currency: "",
      discount: 0,
      selectCurrency:'',
      taxFormData: {
        taxName: "",
        taxRate: 0
      },
      depositFormData: {
        method: "",
        amount: null,
        transactionDate: new Date(),
        details: ""
      },
      priceSectionData:{ sub_total:"", total:"", tax:null, discount:null, tax_name:'' },
      purchasesData: [],
      noPurchaseText: "",
      createPurchasesLoading: false,
      getSinglePurchasesLoading: false,
      getPurchasesLoading: false,
      deletePurchaseLoading: false,
      editPurchaseLoading: false,
      data: {},
      isEdit:this.props.navigation.history.location.pathname==="/CreatePurchase"?true: false,
      subTotal: null,
      total: null,
      singlePurchaseData: {},
      openPdf: false,
      purchasePdfData: {},
      purchasePdfLoading: true,
      languageSelected: ""
    };
    this.wrapperRef = React.createRef<any>();
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    this.getTaxRatesApi();
    this.getCurrencySymbol()
    const selectedLanguage = await getStorageData("lang");
    this.setState({ languageSelected: selectedLanguage });

    if(this.props.openPdftype =="suppliers"){
      this.getPurchasesPdf() 
     }
    this.setState({
      data: {
        ...this.state.data,
        transactionDate: this.state.depositFormData.transactionDate
      },
      formData: {
        ...this.state.formData,
        purchaseDate: this.state.formData.purchaseDate
      }
    });
    this.getPurchases();
    if(await getStorageData(configJSON.purchase_id)) {
      this.getSinglePurchase();
    }
    this.setState({ getSinglePurchasesLoading: true });
    this.setState({ getPurchasesLoading: true });
    document.addEventListener("mousedown", this.handleClickOutside);

    const createPurchaseToaster = await getStorageData(configJSON.createPurchaseToaster);
    const updatePurchaseToaster = await getStorageData(configJSON.updatePurchaseToaster);
    const deletePurchaseToaster = await getStorageData(configJSON.deletePurchaseToaster);
    await removeStorageData(configJSON.createPurchaseToaster);
    await removeStorageData(configJSON.updatePurchaseToaster);
    await removeStorageData(configJSON.deletePurchaseToaster);

    const { t } = this.props;
    if (createPurchaseToaster) {
      this.props.openToastHandler(t('suppliers.purchaseCreated'), "success");
    } else if (updatePurchaseToaster) {
      this.props.openToastHandler(t('suppliers.purchaseUpdated'), "success");
    } else if (deletePurchaseToaster) {
      this.props.openToastHandler(t('suppliers.purchaseDeleted'), "success");
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  tokenExpired = (isEmployee: boolean) => {
    setTimeout(() => {
      localStorage.clear();
      this.props?.history.push(isEmployee ? "/Employee/Login" : "/Login");
    }, 2000);
  }

  errorMessageHandler = async (error: any) => {
    const { token } = error;
    if(token == configJSON.tokenExpired || token == configJSON.invalidToken) {
      this.props.openToastHandler(token, configJSON.errorLabel);
      const isEmployee = await checkLoggedInUser();
      this.tokenExpired(isEmployee);
    }
  }

  getErrorMessage = (error: any) => {
    if (error) {
      this.errorMessageHandler(error);
    }
  }

  extractDataFromMessage = (message: Message) => {
    return {
      apiRequestCallId: message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      ),
      responseJson: message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      ),
      errorResponse: message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      ),
    };
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.createPurchasesApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.setState({ createPurchasesLoading: false });
            this.props?.openToastHandler("You are not authorised.", "error");
          } else {
            this.setState({ createPurchasesLoading: false });
            await setStorageData(configJSON.purchase_id, responseJson?.data?.id);
            await setStorageData(configJSON.createPurchaseToaster, configJSON.createPurchaseValue);
            this.props.navigation.navigate(configJSON.PurchasesData);
          }
        } else if (responseJson?.errors) {
          this.setState({ createPurchasesLoading: false });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ createPurchasesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }else if(apiRequestCallId === this.getCurrencyApiCallId){
        this.getCurrencyApiResponse(responseJson)
      }
    }
    
    this.handleGetSinglePurchase(message);
    this.handleGetPurchase(message);
    this.handleDeletePurchase(message);
    this.handleEditPurchase(message);
    this.handleGetPurchasePdf(message);
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetSinglePurchase = (message: any) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getSinglePurchaseApiCallId) {
        if (!responseJson.errors) {
          this.setState({ singlePurchaseData: responseJson?.data });
          this.handleGetSinglePurchaseData(responseJson);
          this.setState({ getSinglePurchasesLoading: false });
        } else if (responseJson?.errors) {
          this.setState({ getSinglePurchasesLoading: false });
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorMessageHandler(error);
          }
        } else {
          //Check Error Response
          this.setState({ getSinglePurchasesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }
  handleGetSinglePurchaseData = (responseJson: any) => {
    const { purchase_date, price, quantity, item_discription, sub_total, tax_name, tax_percentage } = responseJson?.data?.attributes;
    const formData = {
      purchaseDate: new Date(purchase_date),
      price: price,
      quantity: quantity,
      itemDescription: item_discription
    }
    const subTotal = sub_total
    const total = responseJson?.data?.attributes?.total
    const currency = responseJson?.data?.attributes?.currency
    const discount = responseJson?.data?.attributes?.discount
    const taxFormData = {
      taxName: tax_name,
      taxRate: tax_percentage
    }

    const { payment_method, amount, details, transaction_date,pay_balance,collected } = responseJson?.data?.attributes?.deposites[0]
    const depositFormData = {
      method: payment_method,
      amount: amount,
      transactionDate: new Date(transaction_date),
      details: details
    }

    const data = {
      subTotal: sub_total,
      total: responseJson?.data?.attributes?.total,
      discount: responseJson?.data?.attributes?.discount,
      taxName: tax_name,
      taxRate: tax_percentage,
      method: payment_method,
      amount: amount,
      transactionDate: new Date(transaction_date),
      details: details,
    }
    this.setState({
      formData: formData,
      subTotal: subTotal,
      total: total,
      discount: discount,
      taxFormData: taxFormData,
      depositFormData: depositFormData,
      data: data
      ,priceSectionData:{ sub_total:sub_total, total:total, tax:tax_percentage, discount:discount, tax_name:tax_name },
      currency : responseJson?.data?.attributes?.currency,selectCurrency:responseJson?.data?.attributes?.discount_type,
      collectedPaymentForm: { amount:amount, payment_method:payment_method, transaction_date:new Date(transaction_date), details:details, pay_balance: pay_balance, collected: collected },
    }); 
  }

  handleGetPurchase = (message: any) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getPurchasesApiCallId) {
        if (!responseJson.errors) {
          this.setState({
            purchasesData: responseJson?.data,
            noPurchaseText: responseJson?.message,
          });
          this.setState({ getPurchasesLoading: false });
        } else if (responseJson?.errors) {
          this.setState({ getPurchasesLoading: false });
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorMessageHandler(error);
          }
        } else {
          //Check Error Response
          this.setState({ getPurchasesLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleDeletePurchase = async (message: any) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.deletePurchasesApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.setState({
              deletePurchaseLoading: false,
              deleteDialogOpen: false,
              checkedId: null,
            });
            this.props?.openToastHandler("You are not authorised.", "error");
          } else {
            this.getPurchases();
            this.setState({ deletePurchaseLoading: false, deleteDialogOpen: false });
            this.handleDeletePurchaseToaster();
            this.props.navigation.navigate(configJSON.PurchaseOrder);
            await removeStorageData(configJSON.purchase_id);
          }
        } else if (responseJson?.errors) {
          this.setState({ deletePurchaseLoading: false, deleteDialogOpen: false });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ deletePurchaseLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleDeletePurchaseToaster = async () => {
    const { t } = this.props;
    if(this.state.checkedId) {
      this.props.openToastHandler(t('suppliers.purchaseDeleted'), "success");
      this.setState({ checkedId: null });
    } else {
      await setStorageData(configJSON.deletePurchaseToaster, configJSON.deletePurchaseValue);
    }
  }

  handleEditPurchase = async (message: any) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.editPurchasesApiCallId) {
        if (!responseJson.errors) {
          if (responseJson.message === "you are not authorized") {
            this.setState({ editPurchaseLoading: false });
            this.props?.openToastHandler("You are not authorised.", "error");
          } else {
            this.setState({ editPurchaseLoading: false });
            this.setState({ isEdit: false });
            this.getSinglePurchase();
            await setStorageData(configJSON.updatePurchaseToaster, configJSON.updatePurchaseValue);
            this.props.navigation.navigate(configJSON.PurchaseOrder);
          }
        } else if (responseJson?.errors) {
          this.setState({ editPurchaseLoading: false });
          const error = responseJson?.errors?.[0];
          this.getErrorMessage(error);
        } else {
          //Check Error Response
          this.setState({ editPurchaseLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  handleGetPurchasePdf = (message: any) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.extractDataFromMessage(message);
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getPurchasesPdfApiCallId) {
        if (!responseJson.errors) {
          this.setState({ purchasePdfLoading: false });
          this.setState({ purchasePdfData: responseJson?.data });
        } else if (responseJson?.errors) {
          this.setState({ purchasePdfLoading: false, openPdf: false });
          const error = responseJson?.errors?.[0];
          if (error) {
            this.errorMessageHandler(error);
          }
        } else {
          //Check Error Response
          this.setState({ purchasePdfLoading: false });
          this.parseApiErrorResponse(responseJson);
        }

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
  }

  createPurchase = async () => {
    const { languageSelected,selectCurrency } = this.state;
    const {
      purchaseDate,
      price,
      quantity,
      itemDescription,
    } = this.state.formData
    const {
      currency,
      discount,
      taxName,
      taxRate,
      amount,
      transactionDate,
      details
    } = this.state.data;
    const method = this.state.collectedPaymentForm.payment_method
    let methodAr;
    
    if(method === "التحويل المصرفي") {
      methodAr = "bank_transfar";
    } else if(method === "نقدي") {
      methodAr = "cash";
    } else if(method === "يفحص") {
      methodAr = "cheque";
    } else if(method === "بطاقة إئتمان") {
      methodAr = "credit_card";
    } else if(method === "حوالة مالية") {
      methodAr = "money_order";
    } else if(method === "آخر") {
      methodAr = "other";
    }
    
    const supplier_id = await getStorageData(configJSON.supplier_id);

    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const httpBody = {
      "data": {
        "supplier_id": supplier_id,
        "purchase_date": purchaseDate,
        "quantity": quantity?.trim(),
        "price": price?.trim(),
        "item_discription": itemDescription?.trim(),
        "currency": this.state.currency,
        "sub_total":  this.getSubTotal(this.state.formData),
        "discount_currency": this.state.currency,
        "discount": this.state.priceSectionData.discount||'', "tax_name":this.state.priceSectionData.tax_name||'', "tax_percentage": this.state.priceSectionData.tax || '', "total": this.calculateTotal(),
        "discount_type": this.state.selectCurrency
      },
      "deposite": {
        "payment_method": languageSelected === "ar" ? methodAr : method || null,
        "amount": this.state.collectedPaymentForm.amount || null, "transaction_date": this.state.collectedPaymentForm.transaction_date,  "details": this.state.collectedPaymentForm.details || null
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createPurchasesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.purchasesAPIEndPoint}?lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createPurchasesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
  getSinglePurchase = async () => {
    const purchase_id = await getStorageData(configJSON.purchase_id);
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSinglePurchaseApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.purchasesAPIEndPoint}${purchase_id}&lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPurchasesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
  getPurchases = async () => {
    const { languageSelected } = this.state;
    const supplier_id = await getStorageData(configJSON.supplier_id);
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPurchasesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPurchasesAPIEndPoint}${supplier_id}&lang=${languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPurchasesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  deletePurchase = async () => {
    const purchase_id = await getStorageData(configJSON.purchase_id);
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletePurchasesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.purchasesAPIEndPoint}${purchase_id}&lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deletePurchasesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  editPurchase = async () => {
    const {
      quantity,
      price,
      itemDescription,
    } = this.state.formData
    const {
      currency,
      discount,
      taxName,
      taxRate,
      amount,
      transactionDate,
      details,
    } = this.state.data;
    const subTotal = price * quantity;
    const total = this.calculateTotal();
    const supplier_id = await getStorageData(configJSON.supplier_id);
    const purchase_id = await getStorageData(configJSON.purchase_id);
    const method = this.state.collectedPaymentForm.payment_method
    let methodAr;
    if(method === "التحويل المصرفي") {methodAr = "bank_transfar";}
    else if(method === "نقدي") { methodAr = "cash";}
    else if(method === "يفحص") { methodAr = "cheque"; } 
    else if(method === "بطاقة إئتمان") { methodAr = "credit_card"; } 
    else if(method === "حوالة مالية") { methodAr = "money_order"; } 
    else if(method === "آخر") { methodAr = "other"; }
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const httpBody = {
      "data": {
        "supplier_id": supplier_id,
        "purchase_date": this.state.singlePurchaseData?.purchase_date,
        "quantity": String(quantity)?.trim(),
        "price": String(price)?.trim(),
        "item_discription": itemDescription?.trim(),
        "currency": this.state.currency,
        "sub_total": this.getSubTotal(this.state.formData),
        "discount_currency": this.state.currency,
        "discount": this.state.priceSectionData.discount,"tax_name":this.state.priceSectionData.tax_name,"tax_percentage": this.state.priceSectionData.tax,
        "total":  this.calculateTotal(),"discount_type": this.state.selectCurrency
      },
      "deposite": {
        "payment_method": this.state.languageSelected === "ar" ? methodAr : method,
        "amount": this.state.collectedPaymentForm.amount,"transaction_date":this.state.collectedPaymentForm.transaction_date,"details": this.state.collectedPaymentForm.details
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editPurchasesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.purchasesAPIEndPoint}${purchase_id}&lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchPurchasesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getPurchasesPdf = async () => {
    const purchase_id = await getStorageData(configJSON.purchase_id);
    const header = {
      "Content-Type": configJSON.dashboardContentType,
      "token": await getStorageData(configJSON.authToken),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPurchasesPdfApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPurchasesPdfAPIEndPoint}${purchase_id}&lang=${this.state.languageSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPurchasesAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
  handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      this.setState({
        // depositDialog: false,
        methodDialog: false
      });
      this.setState({
        depositFormData: {
          ...this.state.depositFormData,
          method: this.state.data.method,
          amount: this.state.data.amount,
          transactionDate: this.state.data.transactionDate,
          details: this.state.data.details,
        }
      });
      this.setState({
        taxFormData: {
          ...this.state.taxFormData,
          taxName: this.state.data.taxName,
          taxRate: this.state.data.taxRate,
        }
      });
      this.setState({
        isErrorDiscountFormData: {},
        isErrorTaxFormData: {},
        isErrorDepositFormData: {},
      });
    }
  }
  
  handleClickOpenDialog = async () => {
    const isAuthorized = await checkLoggedInUser();
    this.handleClickOpenDialogCallBack(isAuthorized);
  };

  handleClickOpenDialogCallBack = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({ deleteDialogOpen: true });
    } else {
      this.showOpenToastHandler();
    }
  }

  handleCloseDialog = () => {
    this.setState({ deleteDialogOpen: false });
  };

  handleClickProceedDelete = () => {
    this.deletePurchase();
    this.setState({ deletePurchaseLoading: true });
    setTimeout(() => {
      this.setState({ deleteDialogOpen: false });
      this.setState({ checkedId: null });
    }, 500);
  }

  handleCancelButton = async () => {
    if(this.state.checkedId) {
      this.setState({ checkedId: null });
      await removeStorageData(configJSON.purchase_id);
    } else {
      this.props.navigation.navigate(configJSON.SuppliersData)
    }
  }

  handleOpenDownloadAsPdf = async () => {
    this.setState({ openPdf: !this.state.openPdf });
  }

  handleCloseDownloadPdf = () => {
    this.setState({ openPdf: false });
  }

  handleCreateNewPurchase = async () => {
    const isAuthorized = await performSupplierAuthorizationCheck("supplier_data"); //true = not authorized , false = authorized
    this.handleCreateNewPurchaseCallBack(isAuthorized);
  }

  handleCreateNewPurchaseCallBack = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.props.navigation.navigate(configJSON.CreatePurchases);
    } else {
      this.showOpenToastHandler();
    }
  }

  handleChangeDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ discount: e?.target.value });
  }


  handleChangeTax = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      taxFormData: {...this.state.taxFormData, [e?.target.name]: e?.target.value}
    });
  }
  getCurrencySymbol = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "token": token
    };
    const getCurrencyApiCall = apiCall({
      header: header,
      httpBody: null,
      url: `${configJSON.getCurrencySymbol}`,
      httpMethod: configJSON.validationApiMethodType,
    });
    this.getCurrencyApiCallId = getCurrencyApiCall.messageId;
    runEngine.sendMessage(getCurrencyApiCall.id, getCurrencyApiCall);
  }
  getSubTotal=(data:{purchaseDate: Date,price: number,quantity: number,itemDescription: string})=>{ const subTotal = data?.price * data?.quantity;
    return subTotal
  } 
  handlePaymentModalClose=()=>{this.setState({collectedPaymentForm:{ amount:this.state.collectedPaymentForm.amount, 
    payment_method:this.state.collectedPaymentForm.payment_method, transaction_date:this.state.collectedPaymentForm.transaction_date,
     details:this.state.collectedPaymentForm.details  }})}
  handleChangePaymentMethodRef(selected:{value:string}){this.setState({collectedPaymentForm:{...this.state.collectedPaymentForm,payment_method:selected.value}})}
  handleCollectionPaymentModal = async () => { this.setState({collectedPaymentForm:{ amount:this.state.collectedPaymentForm.amount,
     payment_method:this.state.collectedPaymentForm.payment_method, transaction_date:this.state.collectedPaymentForm.transaction_date, 
     details:this.state.collectedPaymentForm.details, pay_balance:this.state.collectedPaymentForm.pay_balance,collected:this.state.collectedPaymentForm.collected}})};
  handleCollectionPaymentClose = () => {this.setState({ collectedPaymentForm: {  amount: this.state.collectedPaymentForm.amount, 
    payment_method: this.state.collectedPaymentForm.payment_method, transaction_date: this.state.collectedPaymentForm.transaction_date,
    details: this.state.collectedPaymentForm.details}});}
  handleTransactionDate = (date: Date) => { this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, transaction_date: date } }) }
  handleDiscountDialogOpen = () => {
    if(this.state.isEdit) {
      this.setState({ discountDialog: !this.state.discountDialog })
    }}
  handlePayBalance = (event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, pay_balance: event.target?.checked } }); }
  handleCollected = (event: React.ChangeEvent<HTMLInputElement>) => { this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, collected: event.target?.checked } }); }
  currencyDiscount = (discount: number,subTotal:number) =>{
    if(this.state.selectCurrency !== '%'){ const disAmt = this.state.priceSectionData.discount ? this.state.priceSectionData.discount : 0
      return subTotal - disAmt
    }else{ return discount }
  }
  subTotalAfterDiscount = (currencyDiscountAmount:number,subTotal:number) =>{
    if(this.state.selectCurrency !== '%'){ return currencyDiscountAmount }else{ return subTotal - currencyDiscountAmount }
  }
  calculateTotal = () => {
    const subTotal:number = this.getSubTotal(this.state.formData);
    const discAmt= this.state.priceSectionData.discount?this.state.priceSectionData.discount:0
    const taxAmt= this.state.priceSectionData.tax?this.state.priceSectionData.tax:0
    const discount:number = (subTotal * discAmt) / 100;
    const currencyDiscountAmount:number = this.currencyDiscount(discount,subTotal)
    const subtotalAfterDiscount:number = this.subTotalAfterDiscount(currencyDiscountAmount,subTotal);
    const taxAmount:number = (subtotalAfterDiscount * taxAmt) / 100;
    const total:number = subtotalAfterDiscount + taxAmount;
    return total.toFixed(2);  
  };
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ collectedPaymentForm: { ...this.state.collectedPaymentForm, [e.target?.name]: e.target?.value } }); }
  handleCollectionPaymentSave = () =>{this.setState({collectedPaymentForm:{ amount:this.state.collectedPaymentForm.amount, 
    payment_method:this.state.collectedPaymentForm.payment_method, transaction_date:this.state.collectedPaymentForm.transaction_date,
     details:this.state.collectedPaymentForm.details, pay_balance: this.state.collectedPaymentForm.pay_balance,collected: this.state.collectedPaymentForm.collected }})
  }
  getCurrencyApiResponse(responseJson: { currency_code: string,currency_symbol:string,errors:object }) {
    if (responseJson && !responseJson.errors) { this.setState({ currency:responseJson.currency_code }) }
  }

  handleChangeDeposit = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      depositFormData: {...this.state.depositFormData, [e?.target.name]: e?.target.value}
    });
  }
  handlePriceSection = (e:  React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ priceSectionData: { ...this.state.priceSectionData, [e.target?.name]: e.target?.value } });
  }
  handleSaveDeposit = () => {
    const { method, amount, transactionDate, details } = this.state.depositFormData;
    this.setState({ isErrorDepositFormData: this.validateDeposit(this.state.depositFormData) },
      () => {
        if(Object.keys(this.state.isErrorDepositFormData).length === 0) {
          this.setState({
            data: {
              ...this.state.data,
              ...{
                method: method,
                amount: amount,
                transactionDate: transactionDate,
                details: details
              }}
            });
          this.setState({ depositDialog: false });
        }
      }
    );    
  }

  handleChangeFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      formData: {...this.state.formData, [e?.target.name]: e?.target.value}
    });
  }

  handleDateChange = (date: Date) => {
    this.setState({
      formData: {...this.state.formData, purchaseDate: date}
    });
  }

  handleTransactionDateChange = (date: Date) => {
    this.setState({
      depositFormData: {...this.state.depositFormData, transactionDate: date}
    });
  }

  validateFormData = (values: any) => {
    const errors: any = {}
    const regexNumber = /^\s*\d+(\.\d+)?\s*$/;
    const { t } = this.props;

    if (!values.purchaseDate) {
      errors.purchaseDate = t('suppliers.purchaseDateRequired');
    }

    if(values.itemDescription?.length > 500) {
      errors.itemDescription = t('suppliers.itemDescriptionLength');
    }

    if (!values.quantity) {
      errors.quantity = t('suppliers.quantityRequired');
    } else if (!regexNumber.test(values.quantity)) {
      errors.quantity = t('suppliers.numericalError');
    }

    if (!values.price) {
      errors.price = t('suppliers.priceRequired');
    } else if (!regexNumber.test(values.price)) {
      errors.price = t('suppliers.numericalError');
    }

    return errors;
  };

  validateDiscount = (values: any) => {
    const errors: any = {};
    const regexNumber = /^\s*\d+(\.\d+)?\s*$/;
    const { t } = this.props;

    if (this.state.discount && !regexNumber.test(this.state.discount)) {
      errors.discount = t('suppliers.numericalError');
    }

    return errors;
  }
  createTaxRateResponse = (responseJson: { errors: string; }) => { if (responseJson && !responseJson.errors) { this.getTaxRatesApi()  }  }
  getTaxRatesApi = async () => {
    const token = await getStorageData(configJSON.storageToken)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const apiEndPoint = configJSON.getTaxRates

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getTaxListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), apiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  validateTax = (values: any) => {
    const errors: any = {};
    const regexNumber = /^\s*\d+(\.\d+)?\s*$/;
    const regexName = this.state.languageSelected === "ar" ?
    /^\s*[a-zA-Z\u0621-\u064A]+(?:\s[a-zA-Z\u0621-\u064A]+)*\s*$/ :
    /^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
    const { t } = this.props;

    if (values.taxName && !regexName.test(values.taxName)) {
      errors.taxName = t('suppliers.alphabeticalError')
    }
    if (values.taxRate && !regexNumber.test(values.taxRate)) {
      errors.taxRate = t('suppliers.numericalError');
    }

    return errors;
  }

  validateDeposit = (values: any) => {
    const errors: any = {};
    const regexNumber = /^\s*\d+(\.\d+)?\s*$/;
    const { t } = this.props;

    if (values.amount && !regexNumber.test(values.amount)) {
      errors.amount = t('suppliers.numericalError');
    }

    return errors;
  }

  handleRadioChange = async (id: any) => {
    this.setState({ checkedId: id });
    await setStorageData(configJSON.purchase_id, id);
  }


  selectCurrency = (selected:  ValueType<{ label: string; value: string; }, false>) => {
    this.setState({ selectCurrency: selected?.value })
  }
  openTaxPopover = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      this.setState({
        anchorEl: event.currentTarget,
        anchorId: "tax-popover",
      })
  };
  closeTaxPopover = () => {
    this.setState({
      anchorEl: null,
      anchorId: "",
    })
  }
  preventMinus = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === '-') {
        event.preventDefault();
    }
  };
  selectTaxRate = (taxRate: TaxRateResponse) => { this.setState({  priceSectionData:{ ...this.state.priceSectionData, tax_name: taxRate.name, tax: taxRate.rate, } })  }
  handleCancelDiscountDialog = () => {
    this.setState({ discountDialog: false });
    this.setState({
      discount: this.state.data.discount
    });
    this.setState({
        isErrorDiscountFormData: {}
    });
  }

  handleTaxDialogOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({
      taxDialog: !this.state.taxDialog,
      discountDialog: false,
      depositDialog: false,
      taxAnchorEl: event?.currentTarget,
      taxAnchorId: "secondTax-popover",
    });
    this.emptyPriceSectionData("tax")
  }

  handleCancelTaxDialog = () => {
    this.setState({ taxDialog: false });
    this.setState({
      taxFormData: {
        ...this.state.taxFormData,
        taxName: this.state.data.taxName,
        taxRate: this.state.data.taxRate,
      },
      taxAnchorEl: null,
      taxAnchorId: "",
    });
    this.emptyPriceSectionData("tax")
    this.setState({
      isErrorTaxFormData: {}
    });
  }

  handleDepositDialogOpen = () => {
    this.setState({
      depositDialog: !this.state.depositDialog,
      discountDialog: false,
      taxDialog: false,
    });
  }
  emptyPriceSectionData =(keys: string) => {
    if (keys === "discount") { this.setState({ priceSectionData: { ...this.state.priceSectionData, discount: null, } }) } 
    else { this.setState({ priceSectionData: { ...this.state.priceSectionData, tax_name: "", tax: null, } }) }
    
  }
  handleCancelDepositDialog = () => {
    this.setState({ depositDialog: false });
    this.setState({
      depositFormData: {
        ...this.state.depositFormData,
        method: this.state.data.method,
        amount: this.state.data.amount,
        transactionDate: this.state.data.transactionDate,
        details: this.state.data.details,
      }
    });
    this.setState({
      isErrorDepositFormData: {}
    });
  }

  handleSelectMethod = (item: any) => {
    this.setState({
      depositFormData: {
        ...this.state.depositFormData, method: item.value
      }
    });
    this.setState({ methodDialog: false });
  }

  handleOpenMethodDialog = () => {
    this.setState({ methodDialog: !this.state.methodDialog });
  }

  handleCancelPurchase = async () => {
    this.props.navigation.navigate(configJSON.PurchaseOrder);
    await removeStorageData(configJSON.purchase_id);
  }

  handleSavePurchase = async () => {
    const isAuthorized = await performSupplierAuthorizationCheck("supplier_data"); //true = not authorized , false = authorized
    this.handleSavePurchaseCallBack(isAuthorized);
  }

  handleSavePurchaseCallBack = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({ isErrorFormData: this.validateFormData(this.state.formData) },
      () => {
        if(Object.keys(this.state.isErrorFormData).length === 0) {
          this.createPurchase();
          this.setState({ createPurchasesLoading: true });
        }
      }
    );
    } else {
      this.showOpenToastHandler();
    }
  }

  handleClickEditPurchase = async () => {
    const isAuthorized = await performSupplierAuthorizationCheck("supplier_data"); //true = not authorized , false = authorized
    this.handleClickEditPurchaseCallBack(isAuthorized);
  }

  handleClickEditPurchaseCallBack = (isAuthorized: any) => {
    if (!isAuthorized) {
      this.setState({ isEdit: true });
    } else {
      this.showOpenToastHandler();
    }
  }

  showOpenToastHandler = () => {
    const { t } = this.props;
    this.props.openToastHandler(t('suppliers.notAuthorized'), configJSON.errorLabel)
  }

  handleUpdatePurchase = () => {
    this.setState({ isErrorFormData: this.validateFormData(this.state.formData) },
      () => {
        if(Object.keys(this.state.isErrorFormData).length === 0) {
          this.editPurchase();
          this.setState({ editPurchaseLoading: true });
        }
      }
    );
  }
}
// Customizable Area End